/*
 * Copyright 1999-2025 Jagex Ltd.
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window._env_ = {
  API_URL: process.env.API_URL,
  SECURE_COOKIE: process.env.SECURE_COOKIE,
  COOKIE_DOMAIN: process.env.COOKIE_DOMAIN,
  ANALYTICS_MEASUREMENT_ID: process.env.ANALYTICS_MEASUREMENT_ID,
  ANALYTICS_COOKIE_PREFIX: process.env.ANALYTICS_COOKIE_PREFIX,
  COOKIEBOT_ID: process.env.COOKIEBOT_ID,
  RUNESCAPE_ACCOUNT_SETTINGS_LINK: process.env.RUNESCAPE_ACCOUNT_SETTINGS_LINK,
  WEBLOGIN_IMPORT_LINK: process.env.WEBLOGIN_IMPORT_LINK,
  LOGIN_PORTAL_WEBSITE_BASE_URL: process.env.LOGIN_PORTAL_WEBSITE_BASE_URL,
  PLAYER_PORTAL_WEBSITE_BASE_URL: process.env.PLAYER_PORTAL_WEBSITE_BASE_URL,
  ACCOUNT_MANAGEMENT_WEBSITE_URL: process.env.ACCOUNT_MANAGEMENT_WEBSITE_URL,
  JAGEX_LAUNCHER_LINK: process.env.JAGEX_LAUNCHER_LINK,
  JAGEX_ACCOUNTS_INFO_LINK: process.env.JAGEX_ACCOUNTS_INFO_LINK,
  RUNESCAPE_SUPPORT_LINK: process.env.RUNESCAPE_SUPPORT_LINK,
  PAYMENT_WEBSITE_URL: process.env.PAYMENT_WEBSITE_URL
};

export {};
